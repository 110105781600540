
import '../css/NossaEquipe.css';
import PerfilDeColaborador from "./PerfilDeColaborador";

import joseImg from "../Assets/jose.png";
import arnaldoImg from "../Assets/arnaldo.png";
import kleyssonImg from "../Assets/kleysson.png";
import pedroImg from "../Assets/pedro.png";
import denilsonImg from "../Assets/denilson.png";
import alexsandroImg from "../Assets/alex.png";
import gabiImg from "../Assets/gabi.png";
import vulpelImg from "../Assets/vulpel.png";
import kelvynImg from "../Assets/kelvyn.png";
import yuriImg from "../Assets/yuri.png";
import eduardoImg from "../Assets/eduardo.png";
import lucasImg from "../Assets/lucas.png";
import nullImg from "../Assets/null.png";


const colaboradores =[
    {
        id:'00', nome:'José', sobrenome:'Magalhães', img: joseImg, cargo:'Fundador / Tech Lead'},
    {
        id:'01', nome:'Arnaldo', sobrenome:'Melo', img:arnaldoImg, cargo:'Ilustrador / Character Designer'},
    {
        id:'02', nome:'Eduardo ', sobrenome:'Freitas', img:eduardoImg, cargo:'Game Developer'},
    {
        id:'03', nome:'Lucas ', sobrenome:'Alves', img:lucasImg, cargo:'Game Developer'},
    {
        id:'04', nome:'Denilson', sobrenome:'Silva', img:denilsonImg, cargo:'Level Designer'},
    {
        id:'05', nome:'Alexsandro', sobrenome:'Duarte', img:alexsandroImg, cargo:'Game Artist'},
    {
        id:'06', nome:'Yuri  ', sobrenome:'Rafael', img:yuriImg, cargo:'Game Designer'},
    {
        id:'07', nome:'', sobrenome:'', img:nullImg, cargo:''}
];

const NossaEquipe = ()=>{
    return(
        <div id="secao4" className="NossaEquipe">
            <div>
                <h1>
                    Nossa Equipe
                </h1>

                <h1>
                    Pessoas que fazem acontecer
                </h1>
                
            </div>
            

            <div className="perfis-de-colaboradores">
                {
                    colaboradores.map((perfil) => (
                        <li key={perfil.id}><PerfilDeColaborador nome={perfil.nome} sobrenome={perfil.sobrenome} img={perfil.img} cargo={perfil.cargo}/></li>
                    
                    ))
                }
            </div>
        </div>
    )
}

export default NossaEquipe;
import '../css/MostraGame.css';

const MostraGame = ()=>{
   
    return(
        <div id="secao2" className="MostraGame">
            <div className="imagens-do-jogo">

                <div className="imagem-maior" ></div>

                <div className="imagens-menores">
                    <div className="img-menor-1"></div>
                    <div className="img-menor-2"></div>
                </div>

            </div>

            <div className="textos-sobre-o-jogo texto-cor-cinza-claro">
            
                <h2 className="texto-cor-cinza">Sobre Vârcolaci</h2>

                <h1>Que jogo é esse?</h1>
                <hr />
                
                <p >
                    Vârcolaci é um jogo que retrata a batalha entre humanos e lobisomens tendo ainda 
                    como antagonista secundário, os vampiros. No game você pode jogar com Victória, 
                    humana que teve seu pai assassinado pelos lobisomens e busca vingança; e/ou Vendrick, 
                    híbrido entre humano e lobisomem que luta contra as feras da noite em busca de resolver 
                    suas histórias do passado.
                </p>

                <p>
                    O game é baseado no livro Amor entre Lobos de José de Sousa Magalhães, o qual você pode 
                    ler parcialmente <a target="_blank" 
                    href="https://www.wattpad.com/98461420-amor-entre-lobos-pref%C3%A1cio"> aqui </a>. 
                    Escrito em 2008, lançado inicialmente em 2014 e com lançamento de sua segunda edição 
                    prevista para 2024, esta obra serviu de embasamento histórico para toda a construção 
                    do game.
                </p>

            </div>
        </div>
    )
}

export default MostraGame;
import React, { useState } from 'react';
import '../css/MenuAmburguer.css';

const MenuAmburguer = ()=>{
    const [isVisible, setSvisible] = useState(false);

    const toogleVisibility = ()=>{
        setSvisible(!isVisible);
    }

    return(
        <div className="MenuAmburguer">
            <div >
                <div className='image-button' onClick={toogleVisibility}></div>
            </div>
            

            {
                isVisible && 
                    <dl className='menu-suspenso'>
                        <li><a href="">Home</a></li>
                        <li><a href="">Vârcolaci</a></li>
                        <li><a href="">Quem Somos?</a></li>
                        <li><a href="">Equipe</a></li>
                    </dl>
            }
        </div>
    )
}

export default MenuAmburguer;
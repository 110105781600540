import '../css/QuemSomos.css';
const QuemSomos = ()=>{
    return(
        <div id="secao3"  className="QuemSomos">
            <h1>Quem Somos</h1>

            <p className="texto-cor-cinza-claro">
                A Ara Game Studio é um estúdio independente que se destaca na vanguarda da 
                criação de jogos envolventes em 2D, adotando a nostálgica e encantadora estética 
                da pixel arte. Somos apaixonados por narrativas cativantes e jogabilidade emocionante, 
                e nossa equipe de desenvolvedores talentosos trabalha incansavelmente para oferecer 
                experiências de jogo que transportam os jogadores para mundos imaginativos e emocionantes.
            </p>

            <p className="texto-cor-cinza-claro">
                Com um compromisso inabalável com a qualidade e a criatividade, a Ara Game Studio é uma fonte 
                inesgotável de diversão e aventura para todos os entusiastas de jogos que apreciam o charme 
                atemporal da arte pixelizada.
            </p>

            <p className="texto-cor-cinza-claro">
                No coração da Ara Game Studio está a nossa missão de criar jogos que cativem, inspirem 
                e proporcionem uma escapada da realidade. Nossos títulos são uma celebração da riqueza 
                da narrativa e da beleza da pixel art, onde cada detalhe é cuidadosamente elaborado para 
                cativar os sentidos e a imaginação.
            </p>

            <p className="texto-cor-cinza-claro">
                Ao escolher a Ara Game Studio, você está se juntando a uma comunidade de jogadores e 
                criadores apaixonados que compartilham a visão de que os jogos podem ser uma forma de 
                arte, uma fonte de entretenimento inigualável e uma jornada emocionante que vale a pena 
                ser explorada. Venha se juntar a nós enquanto continuamos a criar jogos excepcionais que 
                transcenderão o tempo e levarão você a aventuras inesquecíveis.
            </p>
        </div>
    )
}

export default QuemSomos;
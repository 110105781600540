import logo from './logo.svg';
import './App.css';

import Head from './Components/Head';
import DestaqueGame from './Components/DestaqueGame';
import MostraGame from './Components/MostraGame';
import QuemSomos from './Components/QuemSomos';
import NossaEquipe from './Components/NossaEquipe';
import Bottom from './Components/Bottom';

function App() {
  return (
    <div className="App">
      <div className='fundo-image-top'></div>

      <div className="container">
        <Head />
        <DestaqueGame />
        <div className='cor-do-fundo-fundo'>
        <MostraGame />
        <QuemSomos />
        <NossaEquipe />
        <Bottom />
        </div> 
      </div>
           
    </div>
  );
}

export default App;

import varcolaciImg from '../Assets/varcolaci.png'
import '../css/DestaqueGame.css';


const DestaqueGame = ()=>
{
    return(
        <div className="DestaqueGame">
            <div className="game-nome">
                <div><h1>Vârcolaci: <a className='texto-cor-cinza'>O Game</a> Em breve</h1></div>
                <div>
                    <p>Uma batalha sangrenta entre homanos e lobisomens.</p>
                </div>
            </div>
            <div className="agrupa-moldura-e-detalhes">

                <div className="Moldura-destaque-game">
                    <div className="image-card"></div>

                    <h1 className="fonte-pixel texto-cor-cinza">Em breve</h1>
                    <p>Aguarde</p>

                </div>

                <div className='Detalhes'>
                    <div className='det_01'>
                        <h2 className="texto-cor-cinza fonte-pixel">PC</h2>
                        <p>Plataforma</p>
                    </div>

                    <div className='det_02'>
                        <h2 className='texto-cor-cinza fonte-pixel'>Pixel Art</h2>
                        <p>Estilo</p>
                    </div>

                    <div className='det_03'>
                        <h2 className='texto-cor-cinza fonte-pixel'>2D Platform</h2>
                        <p>Formato</p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default DestaqueGame;

import '../css/Bottom.css'

import Logo from './Logo';
import logoLinkedin from '../Assets/logo_linkedin.png';
import logoInstagram from '../Assets/logo_instagram.png';
import logoItchIo from '../Assets/logo_itch_io.png';

const Bottom = ()=>{
    return(
        <div className="Bottom">
            <div className="menu-bottom">
                <div className="logo-ara-missao-e-redes">
                    <Logo />
                    <p className='texto-cor-cinza-claro'>Surgimos com o propósito de dar vida, através dos games, a grandes histórias.</p>
                    <div className="redes-img-links">
                        
                        <a target="_blank" href="https://www.linkedin.com/company/ara-game-studio">
                           <img src={logoLinkedin} width="45px" height="45px" alt="linkedin" /> 
                        </a>
                        <a target="_blank" href="https://www.instagram.com/aragamestudio/">
                            <img src={logoInstagram} width="45px" height="45px" alt="" />
                        </a>
                        <a target="_blank" href="https://aragamestudio.itch.io/">
                            <img src={logoItchIo} width="45px" height="45px" alt="" />
                        </a>
                        
                        
                    </div>
                </div>

                <div className="apoiar">
                    <h2 className='fonte-pixel'>Apoiar</h2>
                    <p ><a className='texto-cor-cinza-claro' href="">Catarse</a></p>
                    <p className='texto-cor-cinza-claro'> PIX:37240920000197</p>
                </div>

                <div className="redes">
                    <h2 className='fonte-pixel'>Redes</h2>
                    <p ><a className='texto-cor-cinza-claro' target="_blank" href="https://www.linkedin.com/company/ara-game-studio">
                        LinkedIn</a></p>
                    <p ><a className='texto-cor-cinza-claro' target="_blank" href="https://www.instagram.com/aragamestudio/">
                        Instagram</a></p>
                    <p ><a className='texto-cor-cinza-claro' target="_blank" href="https://aragamestudio.itch.io/">
                        Itch.io</a></p>
                </div>

                <div className="contato">
                    <h2 className='fonte-pixel'>Contatos</h2>
                    <p className='texto-cor-cinza-claro'>aragamestudio@gmail.com</p>
                    <p className='texto-cor-cinza-claro'>(86) 999864283</p>
                </div>
            </div>
            <div className="copyright">
                <p className='texto-cor-cinza-claro'>Copyright © 2024 Ara Game Studio | Todos os Direitos Reservados</p>
            </div>
        </div>
    )
}

export default Bottom;
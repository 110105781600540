import '../css/Head.css';

import Menu from './Menu';
import Logo from './Logo';

const Head = ()=>
{
    return (
        <div className="Head">
            <Logo />
            <Menu />
        </div>
    )    
}

export default Head;
import '../css/PerfilDeColaborador.css';

export default function PerfilDeColaborador( {nome, sobrenome, img, cargo} ){
    const pathImage = img;
    return(
        <div className="PerfilDeColaborador">
            <h1 className="nome-do-colaborador">
                {nome}
            </h1>

            <h1 className="sobrenome-do-colaborador">
                {sobrenome}
            </h1>

            <img src={pathImage} width='212px' height='221px' alt="" />

            <p className="texto-cor-cinza-claro">{cargo}</p>
        </div>
    );
}